import {DROP_DOWN} from '~/assets/js/constants'
import {filterBy} from '@progress/kendo-data-query'
import isEqual from 'lodash/isEqual'
import inputValidationMixin from '~/mixins/input_validation'

export default {
  mixins: [inputValidationMixin],
  inheritAttrs: true,
  props: {
    iconClassName: {
      type: String,
      default() {
        return 'k-i-arrow-chevron-down'
      }
    }
  },
  data() {
    return {
      items: this.$attrs[DROP_DOWN.DataItemsProperty].slice(),
      cssClasses: ''
    }
  },
  created() {
    this.$watch(() =>
      this.$attrs[DROP_DOWN.DataItemsProperty], this.onDataItemsChanged
    )
  },
  methods: {
    onDataItemsChanged(newVal) {
      if (!isEqual(newVal, this.items)) {
        this.items = newVal.slice()
      }
    },
    filterChange(event) {
      if (this.skipClientFilter) {
        return
      }
      this.items = this.filterData(event.filter)
    },
    filterData(filter) {
      const data = this.$attrs[DROP_DOWN.DataItemsProperty].slice()
      return filterBy(data, filter)
    },
    onOpen() {
      if (!this.cssClasses.includes(DROP_DOWN.OpenIconClass)) {
        this.cssClasses += ' ' + DROP_DOWN.OpenIconClass
      }
    },
    onClose() {
      if (this.cssClasses.includes(DROP_DOWN.OpenIconClass)) {
        this.cssClasses = this.cssClasses.replace(DROP_DOWN.OpenIconClass, '').trim()
      }
    }
  }
}
